/* You can add global styles to this file, and also import other style files */
@import "styles-app-loading";
// @import "scss/imports.scss";


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.btn-shadow{
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
}

.full-screen-dialog {
    background-color: red;
    width: 50%;
    height: 50%;
    margin: 0;
    padding: 0;
}
*{
    outline: none;
    user-select: none;
    // font-family: "Montserrat", sans-serif;
}

/* Dentro del archivo style.scss */
mat-form-field input[type="number"]::-webkit-inner-spin-button,
mat-form-field input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none; /* Oculta las flechas en navegadores basados en WebKit */
    margin: 0; /* Asegura que no haya margen adicional */
}

mat-form-field input[type="number"] {
    -moz-appearance: textfield; /* Oculta las flechas en Firefox */
}


.my-panel-class{
    // Esta clase es de ver-habitacion-dialog.component y solo se ejecuta cuando  se abre si screen.width<700 (ver hotel-cards.component donde se obre el Dialog).
    // background-color: red;
    mat-dialog-container{
        app-ver-habitacion-dialog{
            height: 100%;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 60px auto 60px !important;          
            .container{                
                // grid-area: span 10;
                box-sizing: border-box;
                height: 100%;
                min-height: none;
                max-height: none;
                // background-color: rgb(55, 155, 217) !important;
                padding: 4px;
                .card-precios{
                  box-sizing: border-box;
                  margin: 0 5px;
                }
            }
            .footer{
                height: 60px;
                // background-color: green;
                margin-bottom: 0px;
            }
        }
    }
}

.editor-paquete-dialog{
  mat-dialog-container{
    .mat-mdc-dialog-content{
      max-height: initial !important;
  }
  app-editor-paquete{
      height: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 50px auto !important;
      // .mat-mdc-dialog-actions{
      //   background-color: blueviolet;
      // }
  }
}      
}



@media print {
  .no-print {
    display: none !important;
    visibility: hidden !important;
  }
  .print {
    display: block !important;
    visibility: visible !important;
  }
}

@media print {

  @page {
    size: A4;
  }
  body * {
    visibility: hidden;
    overflow: auto !important;

  }
  #modal-content,
  #modal-content * {
    visibility: visible !important;
  }
  #modal-content {
    position: relative;    
    left: 0cm !important;
    top: 0cm !important;
    z-index: 1000 !important;
  }
  
  .print * {
    visibility: visible !important;
    // position: relative;    
    // left: 0cm !important;
    // top: 0cm !important;
    z-index: 1000 !important;
    }
    
  
    
  table {
    width: 100%;
    // border-collapse: collapse;
    position: relative;    
    left: 0cm !important;
    top: 0cm !important;
    z-index: 1000 !important;
    overflow: auto !important;
    border-collapse: collapse;
    th, td {
        page-break-inside: auto;
    }
    tr {
        page-break-inside: auto;
        page-break-after: auto;
    }
  }

  mat-tab-header{
    display: none !important;
  }

  }
::ng-depp{
  .mat-mdc-tab-body-content{
      min-height: 100vh;
  }


}

@media print {
  // Asegurar que todo el contenido de los tabs esté visible y renderizado
  mat-tab-body {
    visibility: visible !important;
    position: relative !important;
    height: auto !important;
    overflow: visible !important;
  }

  // Asegurar que solo se imprima el contenido del tab activo
  .mat-mdc-tab-body-active {
    display: block !important;
  }

  .mat-mdc-tab-body:not(.mat-mdc-tab-body-active) {
    display: none !important;
  }

  // Ajustar el contenedor de la tabla para evitar problemas de overflow
  .mat-tab-group, .mat-tab-body-wrapper {
    overflow: visible !important;
    height: auto !important;
  }
}

