.app-loading {
  .logo {
    width: 120px !important;
    height: 50px !important;
    
    // this way asset gets processed by webpack 
    // background-color: red !important;
    background: url(./assets/logos/Recurso10PlaymoTur.png) center center no-repeat !important; 
    background-size:  contain !important;

  }
}